export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    header: "Setting",
    icon: "SettingsIcon",
    children: [
      {
        title: "Users",
        route: "users",
      },
      {
        title: "Role",
        route: "roles",
      },
    ],
  },
];
