export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Department",
    icon: "GridIcon",
    children: [
      {
        title: "Department",
        route: "departments",
      },
      {
        title: "Designation",
        route: "designations",
      },
    ],
  },
  {
    title: "Task Manage",
    icon: "ListIcon",
    children: [
      {
        title: "Default Task",
        route: "default-tasks",
      },
      {
        title: "Default Main Task",
        route: "default-main-tasks",
      },
    ],
  },
  {
    title: "Project",
    icon: "FolderIcon",
    children: [
      {
        title: "Projects",
        route: "projects",
      },
      {
        title: "Todo",
        route: "todo",
      },
      {
        title: "Check List",
        route: "check-list",
      },
    ],
  },
  {
    title: "Setting",
    icon: "SettingsIcon",
    children: [
      {
        title: "Employee Manage",
        route: "employees",
      },
      {
        title: "Role",
        route: "roles",
      },
    ],
  },
];
